import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Fallback } from '@radix-ui/react-avatar';
import type { AvatarFallbackProps as FallbackProps } from '@radix-ui/react-avatar';

import styles from './Avatar.styles';

export interface AvatarFallbackProps
  extends StyleProps<Omit<FallbackProps, 'asChild'>> {}

const AvatarFallback = React.forwardRef<
  ElementRef<typeof Fallback>,
  AvatarFallbackProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Fallback
      className={styles().fallback({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AvatarFallback.displayName = '@backyard-ui/core/AvatarFallback';

export default AvatarFallback;
