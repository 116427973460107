/**
 * All credit goes to MUI for creating the base hook upon which we improved our use case.
 */
import React from 'react';

import { useBackyard, screens } from '@backyard-ui/styles';
import type { Token } from '@backyard-ui/styles';

import { isBrowser, getProviderProps } from '@backyard-ui/utils';

export interface UseMediaQueryOptions {
  /**
   * As `window.matchMedia()` is unavailable on the server,
   * it returns a default matches during the first mount.
   *
   * @default false
   */
  defaultMatches: boolean;

  /**
   * To perform the server-side hydration, the hook needs to render twice.
   * A first time with `defaultMatches`, the value of the server, and a second time with the resolved value.
   * This double pass rendering cycle comes with a drawback: it's slower.
   * You can set this option to `true` if you use the returned value **only** client-side.
   *
   * @default false
   */
  noSsr: boolean;

  /**
   * You can provide your own implementation of `matchMedia`.
   * This can be used for handling an iframe content window.
   */
  matchMedia: typeof window.matchMedia;

  /**
   * You can provide your own implementation of `matchMedia`,
   * it's used when rendering server-side.
   */
  serverMatchMedia:
    | ((query: string) => {
        matches: boolean;
      })
    | null;
}

function useMediaQueryStore(
  query: string,
  options: UseMediaQueryOptions
): boolean {
  const { defaultMatches, matchMedia, serverMatchMedia, noSsr } = options;

  const defaultSnapshot = React.useCallback(
    () => defaultMatches,
    [defaultMatches]
  );

  const getServerSnapshot = React.useMemo(() => {
    if (noSsr && matchMedia) {
      return () => matchMedia!(query).matches;
    }

    if (serverMatchMedia !== null) {
      const { matches } = serverMatchMedia(query);

      return () => matches;
    }

    return defaultSnapshot;
  }, [query, defaultSnapshot, noSsr, matchMedia, serverMatchMedia]);

  const [getSnapshot, subscribe] = React.useMemo(() => {
    if (matchMedia === null) {
      return [defaultSnapshot, () => () => {}];
    }

    const queryList = matchMedia(query);

    /**
     * NOTE:
     * Support for Safari < 14
     * https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent
     * */
    return [
      () => queryList.matches,
      (notify: () => void) => {
        try {
          queryList.addEventListener('change', notify);

          return () => queryList.removeEventListener('change', notify);
        } catch (e) {
          queryList.addListener(notify);

          return () => queryList.removeListener(notify);
        }
      },
    ];
  }, [defaultSnapshot, matchMedia, query]);

  const match = React.useSyncExternalStore(
    subscribe,
    getSnapshot,
    getServerSnapshot
  );

  return match;
}

function useMediaQuery(
  queryInput: keyof typeof screens | (string & {}),
  options: Partial<UseMediaQueryOptions> = {}
) {
  const hasSupport = isBrowser && 'matchMedia' in window;

  const { components } = useBackyard();

  const {
    defaultMatches = false,
    matchMedia = hasSupport ? window.matchMedia : null,
    serverMatchMedia = null,
    noSsr = false,
  } = getProviderProps({
    name: 'UseMediaQuery',
    props: options,
    components,
  });

  const screen = screens[queryInput as Token<'screens'>];

  const query = (screen ? `(min-width: ${screen})` : queryInput).replace(
    /^@media( ?)/m,
    ''
  );

  const match = useMediaQueryStore(query, {
    defaultMatches,
    matchMedia,
    serverMatchMedia,
    noSsr,
  });

  return match;
}

export default useMediaQuery;
