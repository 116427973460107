import React from 'react';
import type { ReactNode } from 'react';

import { useMediaQuery, useQuery } from '@backyard-ui/hooks';
import type { UseBreakpointOptions, UseQueryProps } from '@backyard-ui/hooks';

export interface VisibleProps extends UseBreakpointOptions, UseQueryProps {
  /**
   * The children elements
   */
  children: ReactNode;
}

export function Visible(props: VisibleProps) {
  const { children, noSsr } = props;

  const query = useQuery(props);
  const media = useMediaQuery(query, {
    noSsr,
  });

  return <React.Fragment>{media ? children : null}</React.Fragment>;
}

Visible.displayName = 'Visible';

export default Visible;
