import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-avatar';
import type { AvatarProps as RootProps } from '@radix-ui/react-avatar';

import styles from './Avatar.styles';

export interface AvatarProps extends StyleProps<Omit<RootProps, 'asChild'>> {
  /**
   * The Avatar appearance
   *
   * @default primary
   */
  appearance?:
    | 'critical'
    | 'info'
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'tertiary'
    | 'warning';

  /**
   * The Avatar size
   *
   * @default md
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   * The Avatar variant
   *
   * @default solid
   */
  variant?: 'solid' | 'subtle';
}

const Avatar = React.forwardRef<ElementRef<typeof Root>, AvatarProps>(
  (props, ref) => {
    const {
      appearance = 'primary',
      size = 'md',
      variant = 'solid',
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    return (
      <Root
        className={styles({ appearance, size, variant }).base({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

Avatar.displayName = '@backyard-ui/core/Avatar';

export default Avatar;
