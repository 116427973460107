import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { RadioGroup } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuRadioGroupProps as RadioGroupProps } from '@radix-ui/react-dropdown-menu';

export interface DropdownMenuRadioGroupProps
  extends StyleProps<Omit<RadioGroupProps, 'asChild'>> {}

const DropdownMenuRadioGroup = React.forwardRef<
  ElementRef<typeof RadioGroup>,
  DropdownMenuRadioGroupProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <RadioGroup
      className={UNSAFE_className}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

DropdownMenuRadioGroup.displayName = '@backyard-ui/core/DropdownMenuRadioGroup';

export default DropdownMenuRadioGroup;
