import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content, Portal } from '@radix-ui/react-popover';
import type { PopoverContentProps as ContentProps } from '@radix-ui/react-popover';

import styles from './Popover.styles';

export interface PopoverContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const PopoverContent = React.forwardRef<
  ElementRef<typeof Content>,
  PopoverContentProps
>((props, ref) => {
  const {
    align = 'center',
    alignOffset = -4,
    sideOffset = 2,
    UNSAFE_className,
    UNSAFE_style,
    children,
    ...rest
  } = props;

  return (
    <Portal>
      <Content
        align={align}
        alignOffset={alignOffset}
        sideOffset={sideOffset}
        className={styles().content({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      >
        {children}
      </Content>
    </Portal>
  );
});

PopoverContent.displayName = '@backyard-ui/core/PopoverContent';

export default PopoverContent;
