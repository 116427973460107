import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuTriggerProps as TriggerProps } from '@radix-ui/react-dropdown-menu';

export interface DropdownMenuTriggerProps extends UnstyledProps<TriggerProps> {}

const DropdownMenuTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  DropdownMenuTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

DropdownMenuTrigger.displayName = '@backyard-ui/core/DropdownMenuTrigger';

export default DropdownMenuTrigger;
