import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { Slot } from '@radix-ui/react-slot';

import { createVar } from '@backyard-ui/styles';
import type { StyleProps, Token } from '@backyard-ui/styles';

import { filterFalsyValue } from '@backyard-ui/utils';

import styles from './Heading.styles';

export interface HeadingProps
  extends StyleProps<HTMLAttributes<HTMLHeadingElement>> {
  /**
   * The CSS `text-align` property
   */
  align?: 'left' | 'center' | 'right' | 'justify' | 'start' | 'end';

  /**
   * The CSS `color` property
   *
   * @default black
   */
  color?: Token<'colors'>;

  /**
   * The CSS `line-clamp` property
   */
  noOfLines?: number;

  /**
   * The heading size
   *
   * @default xl
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';

  /**
   * The CSS `text-transform` property
   */
  transform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';

  /**
   * Change the component to the HTML tag or custom component of the only child
   */
  asChild?: boolean;

  /**
   * The CSS `font-style` property
   *
   * @default false
   */
  isItalic?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLHeadingElement>;

  /**
   * The heading children
   */
  children: ReactNode;
}

const Heading = React.forwardRef<ElementRef<'h1'>, HeadingProps>(
  (props, ref) => {
    const {
      align,
      color = 'black',
      noOfLines,
      size = 'xl',
      transform,
      asChild = false,
      isItalic = false,
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    const Component = asChild ? Slot : 'h1';

    const dynamicStyles = filterFalsyValue({
      '--heading-color': createVar(`colors-${color}`),
      '--heading-clamp': noOfLines,
    });

    return (
      <Component
        className={styles({
          align,
          size,
          transform,
          clamp: Boolean(noOfLines),
          isItalic,
          className: UNSAFE_className,
        })}
        style={{
          ...dynamicStyles,
          ...UNSAFE_style,
        }}
        ref={ref}
        {...rest}
      />
    );
  }
);

Heading.displayName = '@backyard-ui/core/Heading';

export default Heading;
