import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content, Portal } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuContentProps as DropdownMenuContentPropsBase } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuContentProps
  extends StyleProps<Omit<DropdownMenuContentPropsBase, 'asChild' | 'loop'>> {
  /**
   * If `true`, keyboard navigation will loop from last item to first, and vice versa.
   *
   * @default false
   */
  isLoop?: boolean;
}

const DropdownMenuContent = React.forwardRef<
  ElementRef<typeof Content>,
  DropdownMenuContentProps
>((props, ref) => {
  const {
    UNSAFE_className,
    UNSAFE_style,
    sideOffset = 8,
    align = 'start',
    isLoop,
    ...rest
  } = props;

  return (
    <Portal>
      <Content
        className={styles.base().content({ className: UNSAFE_className })}
        style={UNSAFE_style}
        sideOffset={sideOffset}
        align={align}
        loop={isLoop}
        ref={ref}
        {...rest}
      />
    </Portal>
  );
});

DropdownMenuContent.displayName = '@backyard-ui/core/DropdownMenuContent';

export default DropdownMenuContent;
