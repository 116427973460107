import React from 'react';
import type { ElementRef, Ref } from 'react';

import { Root } from '@radix-ui/react-dialog';
import type { DialogProps as RootProps } from '@radix-ui/react-dialog';

import { DialogProvider } from './Dialog.context';

export interface DialogProps extends Omit<RootProps, 'open'> {
  /**
   * The `max-width` css property
   *
   * @default xl
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';

  /**
   * If `true` the content will be scrollable
   *
   * @default false
   */
  hasScroll?: boolean;

  /**
   * If `false` the Dialog will be close when clicked on overlay
   *
   * @default true
   */
  closeOnOverlayClick?: boolean;

  /**
   * If `true` the Dialog will be centered on screen
   *
   * @default false
   */
  isCentered?: boolean;

  /**
   * If `true` the Dialog will be initially opened
   *
   * @default false
   */
  isOpen?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Dialog = React.forwardRef<ElementRef<'div'>, DialogProps>(
  (props, ref) => {
    const {
      size = 'xl',
      hasScroll = false,
      closeOnOverlayClick = true,
      isCentered = false,
      isOpen,
      ...rest
    } = props;

    return (
      <DialogProvider
        value={{ size, hasScroll, isCentered, closeOnOverlayClick }}
      >
        <Root ref={ref} open={isOpen} {...rest} />
      </DialogProvider>
    );
  }
);

Dialog.displayName = '@backyard-ui/core/Dialog';

export default Dialog;
