import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Close } from '@radix-ui/react-dialog';
import type { DialogCloseProps as CloseProps } from '@radix-ui/react-dialog';
import { CloseButton } from '../CloseButton';

import styles from './Dialog.styles';

export interface DialogCloseButtonProps
  extends StyleProps<Omit<CloseProps, 'asChild'>> {}

const DialogCloseButton = React.forwardRef<
  ElementRef<'button'>,
  DialogCloseButtonProps
>((props, ref) => {
  const { UNSAFE_className, ...rest } = props;

  return (
    <Close ref={ref} asChild {...rest}>
      <CloseButton
        size="xs"
        UNSAFE_className={styles().close({ className: UNSAFE_className })}
      />
    </Close>
  );
});

DialogCloseButton.displayName = '@backyard-ui/core/DialogCloseButton';

export default DialogCloseButton;
