import React, { ReactElement } from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Item } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuItemProps as DropdownMenuItemPropsBase } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuItemProps
  extends StyleProps<Omit<DropdownMenuItemPropsBase, 'asChild' | 'disabled'>> {
  /**
   *
   */
  icon?: ReactElement;

  /**
   *
   */
  isDisabled?: boolean;
}

const DropdownMenuItem = React.forwardRef<
  ElementRef<typeof Item>,
  DropdownMenuItemProps
>((props, ref) => {
  const {
    UNSAFE_className,
    UNSAFE_style,
    icon,
    isDisabled,
    children,
    ...rest
  } = props;

  return (
    <Item
      className={styles.item().base({ className: UNSAFE_className })}
      style={UNSAFE_style}
      asChild
      disabled={isDisabled}
      ref={ref}
      {...rest}
    >
      <span>
        {icon &&
          React.cloneElement(React.Children.only(icon), {
            className: styles.item().icon(),
          })}
        {children}
      </span>
    </Item>
  );
});

DropdownMenuItem.displayName = '@backyard-ui/core/DropdownMenuItem';

export default DropdownMenuItem;
