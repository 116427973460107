import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Close } from '@radix-ui/react-dialog';
import type { DialogCloseProps as CloseProps } from '@radix-ui/react-dialog';

export interface DialogCloseProps extends UnstyledProps<CloseProps> {}

const DialogClose = React.forwardRef<
  ElementRef<typeof Close>,
  DialogCloseProps
>((props, ref) => {
  return <Close ref={ref} {...props} />;
});

DialogClose.displayName = '@backyard-ui/core/DialogClose';

export default DialogClose;
