import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Dialog.styles';

export interface DialogFooterProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const DialogFooter = React.forwardRef<ElementRef<'footer'>, DialogFooterProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <footer
        className={styles().footer({ className: UNSAFE_className })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

DialogFooter.displayName = '@backyard-ui/core/DialogFooter';

export default DialogFooter;
