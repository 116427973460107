import { createContext } from '@backyard-ui/utils';
import type { DialogProps } from './Dialog';

export interface DialogContext
  extends Pick<
    DialogProps,
    | 'size'
    | 'defaultOpen'
    | 'hasScroll'
    | 'isCentered'
    | 'isOpen'
    | 'closeOnOverlayClick'
    | 'onOpenChange'
  > {}

const { Provider: DialogProvider, useContext: useDialogContext } =
  createContext<DialogContext>({
    name: '@backyard-ui/core/DialogContext',
  });

export { DialogProvider, useDialogContext };
