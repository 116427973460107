import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content, Portal, Overlay } from '@radix-ui/react-dialog';
import type { DialogContentProps as ContentProps } from '@radix-ui/react-dialog';

import { Overlay as OverlayBase } from '../Overlay';
import { useDialogContext } from './Dialog.context';

import styles from './Dialog.styles';

export interface DialogContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const DialogContent = React.forwardRef<
  ElementRef<typeof Content>,
  DialogContentProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, onPointerDownOutside, ...rest } =
    props;
  const { size, hasScroll, isCentered, closeOnOverlayClick } =
    useDialogContext();

  const onOutsideClick = React.useCallback(
    (
      event: CustomEvent<{
        originalEvent: PointerEvent;
      }>
    ) => {
      if (!closeOnOverlayClick) {
        event.preventDefault();

        return onPointerDownOutside?.(event);
      }

      return onPointerDownOutside?.(event);
    },
    [closeOnOverlayClick, onPointerDownOutside]
  );

  return (
    <Portal>
      <div className={styles().portal()}>
        <Overlay asChild>
          <OverlayBase />
        </Overlay>
        <div
          className={styles({ isCentered }).base({
            className: UNSAFE_className,
          })}
          style={UNSAFE_style}
        >
          <Content
            className={styles({ size, hasScroll }).content()}
            onPointerDownOutside={onOutsideClick}
            ref={ref}
            {...rest}
          />
        </div>
      </div>
    </Portal>
  );
});

DialogContent.displayName = '@backyard-ui/core/DialogContent';

export default DialogContent;
