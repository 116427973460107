import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'relative',
  slots: {
    arrow: `
      rotate-45
      border-gray-300
      bg-white

      after:block
      after:h-full
      after:w-full
      after:rounded
      after:bg-white
      after:content-['']

      group-data-[side]:mt-[var(--popover-arrow-size)]
      group-data-[side]:rounded-br
      group-data-[side]:border-b
      group-data-[side]:border-r
      group-data-[side]:border-gray-100
    `,
    header: `
      flex
      items-center
      justify-between

      border-b
      border-gray-100

      px-3
      py-2

      text-md
      font-semibold
    `,
    content: `
      group

      relative
      z-50

      flex
      max-w-[15em]

      origin-[var(--radix-popover-content-transform-origin)]

      flex-col

      rounded-md
      bg-white
      text-neutral-900
      shadow-sm

      outline-none

      will-change-transform
      animate-in
      duration-150

      data-[state=closed]:animate-out
      data-[state=closed]:fade-out-0
      data-[state=closed]:zoom-out-95
      data-[side=bottom]:slide-in-from-top-1
      data-[side=left]:slide-in-from-right-1
      data-[side=right]:slide-in-from-left-1
      data-[side=top]:slide-in-from-bottom-1
      data-[side]:motion-reduce:animate-none
    `,
    body: `
      relative
      z-10

      px-3
      py-3

      text-md
    `,
    footer: `
      border-t
      border-gray-100

      px-3
      py-2

      text-md
    `,
    close: 'ml-6',
  },
});

export default styles;
