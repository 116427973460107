import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Close } from '@radix-ui/react-popover';
import type { PopoverCloseProps as CloseProps } from '@radix-ui/react-popover';

import { CloseButton } from '../CloseButton';

import styles from './Popover.styles';

export interface PopoverCloseButtonProps
  extends StyleProps<Omit<CloseProps, 'asChild'>> {}

const PopoverCloseButton = React.forwardRef<
  ElementRef<typeof Close>,
  PopoverCloseButtonProps
>((props, ref) => {
  const { UNSAFE_className, ...rest } = props;

  return (
    <Close ref={ref} asChild {...rest}>
      <CloseButton
        UNSAFE_className={styles().close({
          className: UNSAFE_className,
        })}
        size="xs"
      />
    </Close>
  );
});

PopoverCloseButton.displayName = '@backyard-ui/core/PopoverCloseButton';

export default PopoverCloseButton;
