import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Separator } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuSeparatorProps as SeparatorProps } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuSeparatorProps
  extends StyleProps<Omit<SeparatorProps, 'asChild'>> {}

const DropdownMenuSeparator = React.forwardRef<
  ElementRef<typeof Separator>,
  DropdownMenuSeparatorProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Separator
      className={styles.base().separator({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      asChild
      {...rest}
    >
      <hr />
    </Separator>
  );
});

DropdownMenuSeparator.displayName = '@backyard-ui/core/DropdownMenuSeparator';

export default DropdownMenuSeparator;
