import React from 'react';
import type { ElementRef, Ref } from 'react';

import { Root } from '@radix-ui/react-popover';
import type { PopoverProps as RootProps } from '@radix-ui/react-popover';

export interface PopoverProps extends Omit<RootProps, 'open'> {
  /**
   * If `true` the Dialog will be initially opened
   *
   * @default false
   */
  isOpen?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Popover = React.forwardRef<ElementRef<'div'>, PopoverProps>(
  (props, ref) => {
    const { isOpen, ...rest } = props;

    return <Root ref={ref} open={isOpen} {...rest} />;
  }
);

Popover.displayName = '@backyard-ui/core/Popover';

export default Popover;
