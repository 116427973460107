import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Description } from '@radix-ui/react-dialog';
import type { DialogDescriptionProps as DescriptionProps } from '@radix-ui/react-dialog';

import styles from './Dialog.styles';

export interface DialogDescriptionProps
  extends StyleProps<Omit<DescriptionProps, 'asChild'>> {}

const DialogDescription = React.forwardRef<
  ElementRef<typeof Description>,
  DialogDescriptionProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  return (
    <Description
      className={styles().description({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      asChild
      {...rest}
    >
      <div>{children}</div>
    </Description>
  );
});

DialogDescription.displayName = '@backyard-ui/core/DialogDescription';

export default DialogDescription;
