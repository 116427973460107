import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Image } from '@radix-ui/react-avatar';
import type { AvatarImageProps as ImageProps } from '@radix-ui/react-avatar';

import styles from './Avatar.styles';

export interface AvatarImageProps
  extends StyleProps<Omit<ImageProps, 'asChild'>> {}

const AvatarImage = React.forwardRef<
  ElementRef<typeof Image>,
  AvatarImageProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Image
      className={styles().image({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AvatarImage.displayName = '@backyard-ui/core/AvatarImage';

export default AvatarImage;
