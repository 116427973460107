import { Sub } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuSubProps as DropdownMenuSubPropsBase } from '@radix-ui/react-dropdown-menu';

export interface DropdownMenuSubProps
  extends Omit<DropdownMenuSubPropsBase, 'defaultOpen' | 'open'> {
  /**
   * The controlled open state of the submenu. Must be used in conjunction with `onOpenChange`.
   *
   * @default false
   */
  isOpen?: boolean;

  /**
   * The open state of the submenu when it is initially rendered. Use when you do not need to control its open state.
   *
   * @default false
   */
  isDefaultOpen?: boolean;
}

const DropdownMenuSub = (props: DropdownMenuSubProps) => {
  const { isOpen, isDefaultOpen, ...rest } = props;

  return <Sub open={isOpen} defaultOpen={isDefaultOpen} {...rest} />;
};

DropdownMenuSub.displayName = '@backyard-ui/core/DropdownMenuSub';

export default DropdownMenuSub;
