export { default as Root } from './Popover';
export type { PopoverProps } from './Popover';

export { default as Header } from './Header';
export type { PopoverHeaderProps } from './Header';

export { default as Trigger } from './Trigger';
export type { PopoverTriggerProps } from './Trigger';

export { default as Anchor } from './Anchor';
export type { PopoverAnchorProps } from './Anchor';

export { default as Content } from './Content';
export type { PopoverContentProps } from './Content';

export { default as Body } from './Body';
export type { BodyProps as PopoverBodyProps } from './Body';

export { default as Footer } from './Footer';
export type { FooterProps as PopoverFooterProps } from './Footer';

export { default as Arrow } from './Arrow';
export type { PopoverArrowProps } from './Arrow';

export { default as Close } from './Close';
export type { PopoverCloseProps } from './Close';

export { default as CloseButton } from './CloseButton';
export type { PopoverCloseButtonProps } from './CloseButton';
