import React from 'react';
import type { ElementRef, CSSProperties } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Arrow } from '@radix-ui/react-popover';
import type { PopoverArrowProps as ArrowProps } from '@radix-ui/react-popover';

import styles from './Popover.styles';

export interface PopoverArrowProps
  extends StyleProps<Omit<ArrowProps, 'asChild'>> {}

const PopoverArrow = React.forwardRef<
  ElementRef<typeof Arrow>,
  PopoverArrowProps
>((props, ref) => {
  const {
    width = 10,
    height = 10,
    UNSAFE_className,
    UNSAFE_style,
    ...rest
  } = props;

  /**
   * arrow size + content border
   */
  const style = {
    '--popover-arrow-size': `${-width / 2 + 1}px`,
  } as CSSProperties;

  return (
    <Arrow
      className={styles().arrow({
        className: UNSAFE_className,
      })}
      style={{ ...style, ...UNSAFE_style }}
      width={width}
      height={height}
      ref={ref}
      asChild
      {...rest}
    >
      <svg />
    </Arrow>
  );
});

PopoverArrow.displayName = '@backyard-ui/core/PopoverArrow';

export default PopoverArrow;
