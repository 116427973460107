import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Title } from '@radix-ui/react-dialog';
import type { DialogTitleProps as TitleProps } from '@radix-ui/react-dialog';

import styles from './Dialog.styles';

export interface DialogTitleProps
  extends StyleProps<Omit<TitleProps, 'asChild'>> {}

const DialogTitle = React.forwardRef<
  ElementRef<typeof Title>,
  DialogTitleProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Title
      className={styles().title({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

DialogTitle.displayName = '@backyard-ui/core/DialogTitle';

export default DialogTitle;
