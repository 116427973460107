import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Anchor } from '@radix-ui/react-popover';
import type { PopoverAnchorProps as AnchorProps } from '@radix-ui/react-popover';

export interface PopoverAnchorProps extends StyleProps<AnchorProps> {}

const PopoverAnchor = React.forwardRef<
  ElementRef<typeof Anchor>,
  PopoverAnchorProps
>((props, ref) => {
  return <Anchor ref={ref} {...props} />;
});

PopoverAnchor.displayName = '@backyard-ui/core/PopoverAnchor';

export default PopoverAnchor;
