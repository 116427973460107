import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-popover';
import type { PopoverTriggerProps as TriggerProps } from '@radix-ui/react-popover';

export interface PopoverTriggerProps extends UnstyledProps<TriggerProps> {}

const PopoverTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  PopoverTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

PopoverTrigger.displayName = '@backyard-ui/core/PopoverTrigger';

export default PopoverTrigger;
