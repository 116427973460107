import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { ItemIndicator } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuItemIndicatorProps as IndicatorProps } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuItemIndicatorProps
  extends StyleProps<Omit<IndicatorProps, 'asChild' | 'disabled'>> {}

const DropdownMenuItemIndicator = React.forwardRef<
  ElementRef<typeof ItemIndicator>,
  DropdownMenuItemIndicatorProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <ItemIndicator
      className={styles
        .item({ indicator: true })
        .icon({ className: UNSAFE_className })}
      style={UNSAFE_style}
      asChild
      ref={ref}
      {...rest}
    />
  );
});

DropdownMenuItemIndicator.displayName =
  '@backyard-ui/core/DropdownMenuItemIndicator';

export default DropdownMenuItemIndicator;
