import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    font-bold
    text-[var(--heading-color)]
  `,
  variants: {
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
      justify: 'text-justify',
      start: 'text-start',
      end: 'text-end',
    },
    size: {
      xs: 'heading-xs',
      sm: 'heading-sm',
      md: 'heading-md',
      lg: 'heading-lg',
      xl: `
        heading-lg
        md:heading-xl
      `,
      '2xl': `
        heading-xl
        md:heading-2xl
      `,
      '3xl': `
        heading-2xl
        md:heading-3xl
      `,
      '4xl': `
        heading-2xl
        md:heading-4xl
      `,
    },
    transform: {
      uppercase: 'uppercase',
      lowercase: 'lowercase',
      capitalize: 'capitalize',
      none: 'normal-case',
    },
    clamp: {
      true: 'line-clamp-[var(--heading-clamp)]',
      false: 'block',
    },
    isItalic: {
      true: 'italic',
    },
  },
});

export default styles;
