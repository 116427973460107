import React from 'react';
import type { ElementRef, Ref } from 'react';

import { Root } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuProps as DropdownMenuPropsBase } from '@radix-ui/react-dropdown-menu';

export interface DropdownMenuProps
  extends Omit<DropdownMenuPropsBase, 'modal' | 'open'> {
  /**
   * The modality of the dropdown menu. When set to `true`, interaction with outside elements will be disabled and only menu content will be visible to screen readers.
   */
  isModal?: boolean;

  /**
   * If `true` the Dialog will be initially opened
   *
   * @default false
   */
  isOpen?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const DropdownMenu = React.forwardRef<ElementRef<'div'>, DropdownMenuProps>(
  (props, ref) => {
    const { isModal, isOpen, ...rest } = props;

    return <Root modal={isModal} open={isOpen} ref={ref} {...rest} />;
  }
);

DropdownMenu.displayName = '@backyard-ui/core/DropdownMenu';

export default DropdownMenu;
