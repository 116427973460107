import React from 'react';
import type { HTMLAttributes, ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Popover.styles';

export interface PopoverHeaderProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {}

const PopoverHeader = React.forwardRef<
  ElementRef<'header'>,
  PopoverHeaderProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <header
      className={styles().header({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

PopoverHeader.displayName = '@backyard-ui/core/PopoverHeader';

export default PopoverHeader;
