export { default as Root } from './Dialog';
export type { DialogProps } from './Dialog';

export { default as Trigger } from './Trigger';
export type { DialogTriggerProps } from './Trigger';

export { default as Close } from './Close';
export type { DialogCloseProps } from './Close';

export { default as CloseButton } from './CloseButton';
export type { DialogCloseButtonProps } from './CloseButton';

export { default as Content } from './Content';
export type { DialogContentProps } from './Content';

export { default as Title } from './Title';
export type { DialogTitleProps } from './Title';

export { default as Description } from './Description';
export type { DialogDescriptionProps } from './Description';

export { default as Footer } from './Footer';
export type { DialogFooterProps } from './Footer';
