import { screens } from '@backyard-ui/styles';
import type { Token } from '@backyard-ui/styles';

export interface UseQueryProps {
  /**
   * Custom CSS media query
   */
  query?: string;

  /**
   * A value from the breakpoints section in the theme.
   * eg: base, sm, md, lg, xl, 2xl
   */
  below?: keyof typeof screens | (string & {});

  /**
   * A value from the breakpoints section in the theme.
   * eg: base, sm, md, lg, xl, 2xl
   */
  above?: keyof typeof screens | (string & {});
}

function useQuery(props: UseQueryProps) {
  let { query = '', below, above } = props;

  const bpBelow = screens[below as Token<'screens'>] ?? below;
  const bpAbove = screens[above as Token<'screens'>] ?? above;

  if (bpBelow) {
    query = `(max-width: ${bpBelow})`;
  } else if (bpAbove) {
    query = `(min-width: ${bpAbove})`;
  }

  return query;
}

export default useQuery;
