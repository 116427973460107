import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-dialog';
import type { DialogTriggerProps as TriggerProps } from '@radix-ui/react-dialog';

export interface DialogTriggerProps extends UnstyledProps<TriggerProps> {}

const DialogTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  DialogTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

DialogTrigger.displayName = '@backyard-ui/core/DialogTrigger';

export default DialogTrigger;
