import { tv } from 'tailwind-variants';

export const base = tv({
  slots: {
    content: `
      relative
      z-50

      flex
      min-w-[theme('spacing.44')]

      origin-[var(--radix-dropdown-menu-content-transform-origin)]

      flex-col
      space-y-1

      rounded-md
      bg-white
      p-1
      shadow-xs

      will-change-transform
      animate-in
      duration-150

      data-[side=bottom]:slide-in-from-top-1
      data-[side=left]:slide-in-from-right-1
      data-[side=right]:slide-in-from-left-1
      data-[side=top]:slide-in-from-bottom-1
      data-[side]:motion-reduce:animate-none
    `,
    group: `
      flex
      flex-col
    `,
    label: `
      px-3
      py-1

      text-sm
      font-semibold
      text-neutral-700
    `,
    separator: `
      -mx-1
      my-1

      h-px
      w-[calc(100%+theme('spacing.2'))]

      border-none
      bg-neutral-100
    `,
  },
});

export const item = tv({
  slots: {
    base: `
      relative
      flex
      w-full

      cursor-pointer
      select-none

      items-center

      rounded-md
      border-none

      bg-transparent

      px-3
      py-2

      text-md
      text-neutral-900

      outline-none

      transition-colors
      duration-75
      ease-in

      hover:bg-neutral-100
      focus:bg-neutral-100

      data-[disabled]:cursor-not-allowed
      data-[disabled]:text-neutral-300
      data-[disabled]:hover:bg-transparent
    `,
    flex: `
      flex
      w-full
      items-center
    `,
    icon: `
      mr-2

      inline-flex

      h-3
      w-3

      items-center
    `,
    input: 'pl-9',
  },
  variants: {
    indicator: {
      true: {
        icon: `
          absolute
          left-3
          mr-2
        `,
      },
    },
    input: {
      true: {
        base: 'pl-8',
      },
    },
  },
});
