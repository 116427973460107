import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Label } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuLabelProps as MenuLabelProps } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuLabelProps
  extends Omit<StyleProps<MenuLabelProps>, 'asChild'> {}

const DropdownMenuLabel = React.forwardRef<
  ElementRef<typeof Label>,
  DropdownMenuLabelProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Label
      className={styles.base().label({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

DropdownMenuLabel.displayName = '@backyard-ui/core/DropdownMenuLabel';

export default DropdownMenuLabel;
