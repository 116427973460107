import React from 'react';
import type { ElementRef, ReactElement } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { SubTrigger } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuSubTriggerProps as DropdownMenuSubTriggerPropsBase } from '@radix-ui/react-dropdown-menu';

import { ChevronRight } from '@backyard-ui/icons';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuSubTriggerProps
  extends StyleProps<
    Omit<DropdownMenuSubTriggerPropsBase, 'asChild' | 'disabled'>
  > {
  /**
   * Custom icon element
   */
  icon?: ReactElement;

  /**
   * If `true`, prevents the user from interacting with the item.
   */
  isDisabled?: boolean;
}

const DropdownMenuSubTrigger = React.forwardRef<
  ElementRef<typeof SubTrigger>,
  DropdownMenuSubTriggerProps
>((props, ref) => {
  const {
    UNSAFE_className,
    UNSAFE_style,
    isDisabled,
    icon,
    children,
    ...rest
  } = props;

  return (
    <SubTrigger
      className={styles.item().base({ className: UNSAFE_className })}
      style={UNSAFE_style}
      disabled={isDisabled}
      asChild
      ref={ref}
      {...rest}
    >
      <div>
        <span className={styles.item().flex()}>
          {icon &&
            React.cloneElement(React.Children.only(icon), {
              className: styles.item().icon(),
            })}
          {children}
        </span>
        <ChevronRight className={styles.item().icon({ className: 'mr-0' })} />
      </div>
    </SubTrigger>
  );
});

DropdownMenuSubTrigger.displayName = '@backyard-ui/core/DropdownMenuSubTrigger';

export default DropdownMenuSubTrigger;
