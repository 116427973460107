import React from 'react';
import type { HTMLAttributes, ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Popover.styles';

export interface BodyProps extends StyleProps<HTMLAttributes<HTMLDivElement>> {}

const PopoverBody = React.forwardRef<ElementRef<'div'>, BodyProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <div
        className={styles().body({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

PopoverBody.displayName = '@backyard-ui/core/PopoverBody';

export default PopoverBody;
