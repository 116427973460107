import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { RadioItem } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuRadioItemProps as RadioItemProps } from '@radix-ui/react-dropdown-menu';

import ItemIndicator from './ItemIndicator';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuRadioItemProps
  extends StyleProps<Omit<RadioItemProps, 'asChild' | 'disabled'>> {
  /**
   * If `true`, prevents the user from interacting with the item.
   *
   * @default false
   */
  isDisabled?: boolean;
}

const DropdownMenuRadioItem = React.forwardRef<
  ElementRef<typeof RadioItem>,
  DropdownMenuRadioItemProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, isDisabled, children, ...rest } =
    props;

  return (
    <RadioItem
      className={styles
        .item({ input: true })
        .base({ className: UNSAFE_className })}
      style={UNSAFE_style}
      disabled={isDisabled}
      ref={ref}
      {...rest}
    >
      <span className={styles.item().flex()}>
        <ItemIndicator>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 32 32">
            <circle cx="16" cy="16" r="8" fill="currentColor" />
          </svg>
        </ItemIndicator>
        {children}
      </span>
    </RadioItem>
  );
});

DropdownMenuRadioItem.displayName = '@backyard-ui/core/DropdownMenuRadioItem';

export default DropdownMenuRadioItem;
