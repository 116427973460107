import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Close } from '@radix-ui/react-popover';
import type { PopoverCloseProps as CloseProps } from '@radix-ui/react-popover';

export interface PopoverCloseProps extends UnstyledProps<CloseProps> {}

const PopoverClose = React.forwardRef<
  ElementRef<typeof Close>,
  PopoverCloseProps
>((props, ref) => {
  return <Close ref={ref} {...props} />;
});

PopoverClose.displayName = '@backyard-ui/core/PopoverClose';

export default PopoverClose;
