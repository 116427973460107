import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { SubContent } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuSubContentProps as SubContentProps } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuSubContentProps
  extends StyleProps<Omit<SubContentProps, 'asChild' | 'loop'>> {
  /**
   * If `true`, keyboard navigation will loop from last item to first, and vice versa.
   *
   * @default false
   */
  isLoop?: boolean;
}

const DropdownMenuSubContent = React.forwardRef<
  ElementRef<typeof SubContent>,
  DropdownMenuSubContentProps
>((props, ref) => {
  const {
    UNSAFE_className,
    UNSAFE_style,
    sideOffset = -6,
    alignOffset = -8,
    isLoop,
    ...rest
  } = props;

  return (
    <SubContent
      className={styles.base().content({ className: UNSAFE_className })}
      style={UNSAFE_style}
      alignOffset={alignOffset}
      sideOffset={sideOffset}
      loop={isLoop}
      ref={ref}
      {...rest}
    />
  );
});

DropdownMenuSubContent.displayName = '@backyard-ui/core/DropdownMenuSubContent';

export default DropdownMenuSubContent;
