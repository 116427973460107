import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Group } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuGroupProps as MenuGroupProps } from '@radix-ui/react-dropdown-menu';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuGroupProps
  extends StyleProps<Omit<MenuGroupProps, 'asChild'>> {}

const DropdownMenuGroup = React.forwardRef<
  ElementRef<typeof Group>,
  DropdownMenuGroupProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Group
      className={styles.base().group({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

DropdownMenuGroup.displayName = '@backyard-ui/core/DropdownMenuGroup';

export default DropdownMenuGroup;
