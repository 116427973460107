import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { CheckboxItem } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuCheckboxItemProps as DropdownMenuCheckboxItemPropsBase } from '@radix-ui/react-dropdown-menu';

import { Checkmark } from '@backyard-ui/icons';
import ItemIndicator from './ItemIndicator';

import * as styles from './DropdownMenu.styles';

export interface DropdownMenuCheckboxItemProps
  extends StyleProps<
    Omit<DropdownMenuCheckboxItemPropsBase, 'asChild' | 'checked' | 'disabled'>
  > {
  /**
   *
   */
  isChecked?: boolean | 'indeterminate';

  /**
   *
   */
  isDisabled?: boolean;
}

const DropdownMenuCheckboxItem = React.forwardRef<
  ElementRef<typeof CheckboxItem>,
  DropdownMenuCheckboxItemProps
>((props, ref) => {
  const {
    UNSAFE_className,
    UNSAFE_style,
    isChecked,
    isDisabled,
    children,
    ...rest
  } = props;

  return (
    <CheckboxItem
      className={styles
        .item({ input: true })
        .base({ className: UNSAFE_className })}
      style={UNSAFE_style}
      checked={isChecked}
      disabled={isDisabled}
      ref={ref}
      {...rest}
    >
      <span>
        <ItemIndicator>
          <Checkmark />
        </ItemIndicator>
        {children}
      </span>
    </CheckboxItem>
  );
});

DropdownMenuCheckboxItem.displayName =
  '@backyard-ui/core/DropdownMenuCheckboxItem';

export default DropdownMenuCheckboxItem;
